.mask-holder {
    mask-image: url('../../assets/svg/logo_qs_spinner.svg');
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
 

    mask-size: contain;
}

.mask {
    width: 200px;


    
    height: 200px;
    position: relative;
    background: conic-gradient(#256ACB, #52F991, #FAE663, #FF5754, #F966BF, #0840CF, #1D3E98, #256ACB);
    animation: animate 8s linear infinite;
}

.mask-no-animate{
    animation: none;
}

.holder {
    position: relative;
}

.symbol {
    width: 200px;
    height: 200px;
    position: absolute;
    background: url('../../assets/svg/logo_qs_cruz.svg');
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
}
.symbol-2 {
    width: 200px;
    height: 200px;
    position: absolute;
    background: url('../../assets/svg/logo_qs_cruz-2.svg');
    background-repeat: no-repeat;
    background-size: contain;
  
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.micro {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height:50px;
    background-color: var(--primary);
    border-radius: 50%;
  }
  
  .micro:before,
  .micro:after {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 2px #0083213b;
    border-radius: 50%;
    z-index: 100;
  }
  
  .micro:before {
    animation: ripple 2s linear infinite;
  }
  
  .micro:after {
    animation: ripple 2s 1s linear infinite;
  }
  
  @keyframes ripple {
    to {
      transform: scale(1.5);
      opacity: 0;
    }
  }
.mask-hover{
    transform: scale(1);
    transition: all 0.3s ease-in-out;

}
  .mask-hover:hover{
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }